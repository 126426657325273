import React from "react";
import "./styles.css";
import img_not_found from "../../assets/images/ni.jpg";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export const ShareWishCard = ({ wish }) => {
  Fancybox.bind("[data-fancybox='gallery']", {
    // Your custom options
  });

  return (
    <div className="share-wish-card-box col-12 col-md-6 col-lg-4">
      <a
        href={wish.images.image1 ? wish.images.image1 : img_not_found}
        data-fancybox="gallery"
        data-caption={
          wish.price.includes("$")
            ? "<div style='text-align: center;'>" +
              wish.title +
              "<br />" +
              wish.price +
              "<div/>"
            : "<div style='text-align: center;'>" +
              wish.title +
              "<br />$" +
              wish.price +
              "<div/>"
        }
      >
        <img
          src={wish.images.image1 ? wish.images.image1 : img_not_found}
          alt="imagen deseo"
          className="share-wish-card-img"
        />
      </a>
      <h5 className="share-wish-card-title">{wish.title}</h5>
      {wish.price && wish.price !== "null" && <div className="share-wish-card-price">
        {wish.price.includes("$") ? wish.price : "$" + wish.price}
      </div>}
    </div>
  );
};

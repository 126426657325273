import React from "react";
import "./styles.css";
import google from "../../assets/images/Logo-Google-Play.png";
import google_dark from "../../assets/images/Logo-Google-Play-negativo.png";
import apple from "../../assets/images/Logo-App-Store.png";
import apple_dark from "../../assets/images/Logo-App-Store-negativo.png";
import logo_giftoky from "../../assets/images/giftoky-blanco.png";
import facebook from "../../assets/images/ico-face2.png";
import instagram from "../../assets/images/ico-ins.png";
import x from "../../assets/images/ico-twitter23.png";

const ShareWishFooter = () => {
  return (
    <>
      <div className="share-footer-box">
        <div className="links-stores">
          <img src={google_dark} alt="google play" className="store-img" />
          <img src={apple_dark} alt="app store" className="store-img" />
        </div>
      </div>
      <div className="share-wish-footer-box">
        <FirstShareWishFooterSection />
      </div>
      <div className="share-wish-footer-box2">
        <SecondShareWishFooterSection />
      </div>
      <div className="share-wish-footer-box">
        <FinalShareWishFooterSection />
      </div>
    </>
  );
};

const FirstShareWishFooterSection = () => {
  return (
    <div className="first-footer-section">
      <div className="join-giftoky">
        <img
          src={logo_giftoky}
          alt="logo giftoky"
          className="logo-giftoky-footer"
        />
        <div className="space"></div>
        <div className="bold-text">
          Súmate a GIFTOKY® gratis y descubre la nueva forma de regalar.
        </div>
        <div className="space"></div>
        <div className="normal-text">
        Sigue ahora a tu amig@, reserva su mejor deseo antes que tod@s y entrega el regalo correcto.
        </div>
        <div className="normal-text">
          Descarga la App hoy mismo, crea tu cuenta y comienza a mejorar tus regalos.
        </div>
        <div className="space"></div>
        <DownloadAppContainer />
      </div>
    </div>
  );
};

const SecondShareWishFooterSection = () => {
  return (
    <div className="second-footer-section">
      <div className="join-giftoky2">
        <div className="space2"></div>
        <div className="bold-text2">
        ¡Todas tus ideas de compras de cualquier tienda en un solo lugar y gratis!
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
        ¿Tienes en mente esa prenda perfecta o el gadget que tanto deseas? Guarda fácilmente tus deseos durante todo el año, para que nunca olvides esos detalles especiales. Mantén tus ideas de compras organizadas en un solo lugar y, lo mejor de todo, compártelos con tus amig@s sin tener que usar complicados navegadores web.

        </div>
        <div className="space2"></div>
        <div className="bold-text2">
        Recibe regalos que realmente quieres, sin complicaciones.
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
        ¿Cansad@ de recibir regalos que no encajan contigo? ¡Eso se acabó! Con GIFTOKY®, puedes invitar a tus amig@s a seguir tu perfil y ver tus deseos públicos. Deja que te sorprendan reservando entre tus sugerencias de forma anónima, asegurándote de recibir solo lo que realmente te emociona. Comparte fácilmente tus deseos más especiales con familiares y amig@s a través de tu plataforma favorita y aumenta tus posibilidades de recibir los regalos perfectos, sin devoluciones ni decepciones.
        </div>
        <div className="space2"></div>
        <div className="bold-text2">
        Ahorra tiempo y conviértete en maestr@ de los regalos
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
        ¿Sabes qué quieren tus amig@s? Deja de adivinar y empieza a regalar con precisión. Explora los deseos de tus contactos, descubre lo que realmente quieren, y selecciona el obsequio perfecto. Reserva de manera anónima para evitar duplicaciones y asegurar que tu regalo sea único, exclusivo, y lo más importante: ¡totalmente acertado!
        </div>
        <div className="space2"></div>
        <div className="bold-text2">
        Despídete del estrés de dar y recibir regalos
        </div>
        <div className="space2"></div>
        <div className="normal-text2">
        Sabemos lo frustrante que puede ser recibir un regalo que no va contigo o gastar horas buscando el regalo perfecto sin éxito. Con GIFTOKY®, tanto regalar como recibir se convierte en una experiencia placentera y sin preocupaciones. Al conocer exactamente lo que tus seres queridos desean, puedes relajarte sabiendo que tu elección será acertada. Y al compartir tus propios deseos, te aseguras de que no tendrás que lidiar con devoluciones o regalos que no necesitas.
        </div>
        <div className="space2"></div>
        <div className="bold-text2">Regala y consume con propósito</div>
        <div className="space2"></div>
        <div className="normal-text2">
        No caigas en el consumismo sin sentido. Ayuda a evitar que los malos regalos terminen como residuos innecesarios. Con nuestra App, elige y da solo aquello que será utilizado y apreciado, cuidando tanto tu dinero como el medio ambiente. Regalar con intención y conciencia nunca fue tan fácil.
        </div>
        <div className="space2"></div>
        <DownloadAppContainer darkMode={false} />
      </div>
    </div>
  );
};

const FinalShareWishFooterSection = () => {
  return (
    <div className="first-footer-section">
      <div className="space2"></div>
      <div className="join-giftoky">
        <div className="final-footer-links">
          <a
            href="https://giftoky.com/politicas-de-privacidad/"
            target="_blank"
            rel="noreferrer"
            className="normal-text"
          >
            Políticas de Privacidad
          </a>
          <a
            href="https://giftoky.com/terminos-y-condiciones/"
            target="_blank"
            rel="noreferrer"
            className="normal-text"
          >
            Términos y Condiciones
          </a>
        </div>
        <div className="space"></div>
        <a href="https://www.giftoky.com/" className="bold-text2">
          www.giftoky.com
        </a>
        {/* <div className="space"></div> */}
        <div className="normal-text">
          © 2023. GIFTOKY Co. Derechos reservados.
        </div>
        <div className="space2"></div>
        <FooterRRSSContainer />
      </div>
    </div>
  );
};

const DownloadAppContainer = ({ darkMode = true }) => {
  return (
    <>
      <div className="bold-text" style={{ display: "block" }}>
      </div>
      <div className="links-stores">
        {/* <a href="#"> */}
        <img
          src={darkMode ? google_dark : google}
          alt="google play"
          className="store-img"
        />
        {/* </a> */}
        {/* <a href="#"> */}
        <img
          src={darkMode ? apple_dark : apple}
          alt="app store"
          className="store-img"
        />
        {/* </a> */}
      </div>
    </>
  );
};

const FooterRRSSContainer = () => {
  return (
    <>
      <div className="rrss-footer">
        <a
          href="https://www.facebook.com/giftokyapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src={facebook} alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/giftokyapp/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={instagram} alt="isntagram" />
        </a>
        <a
          href="https://twitter.com/i/flow/login?redirect_after_login=%2Fgiftokyapp"
          target="_blank"
          rel="noreferrer"
        >
          <img src={x} alt="x" />
        </a>
      </div>
      <div className="key-app-words">
        <div className="normal-text">@GIFTOKYAPP</div>
        <div className="normal-text">#MEJORESREGALOS</div>
      </div>
    </>
  );
};

export default ShareWishFooter;
